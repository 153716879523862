.offerletter-container {
  font-family: "Roboto", sans-serif;
  color: #222222;
  background-color: #efefef;
  background-image: radial-gradient(#999999 1px, transparent 0);
  background-size: 20px 20px;
  display: flex;
  /* height: 100vh; */
  /* margin: 32px 0; */
}

.custom-container {
  max-width: 80%;
  width: 580px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-container h1 {
  font-size: 32px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 24px;
}

.item {
  border: 2px solid #222222;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  will-change: transform;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.item:hover {
  border-color: #7e3af2;
  transform: scale(1.025);
}

.svg-custom {
  width: 36px;
  height: 36px;
  transition: all 0.3s ease-in-out;
}

.item:hover .custom-svg {
  color: #7e3af2;
  fill: red;
}

.custom-button {
  all: unset;
  margin-left: auto;
  background-color: #7e3af2;
  padding: 12px 16px;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.custom-button:hover {
  background-color: #7126f1;
}

.item .filedata {
  display: flex;
  gap: 4px;
  font-size: 12px;
  margin-top: 8px;
  color: #888888;
}
.upload-button {
  background-color: #7e3af2;
  padding: 8px 12px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.upload-button:hover {
  background-color: #7126f1;
}
.domain-list {
  list-style-type: none;
  padding: 0;
  margin: 8px 0;
}

.domain-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
