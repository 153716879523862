.file-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.task-heading{
  font-size: 24px;
  margin-bottom: 20px;
}

.custom-file-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-uploader.dragging {
  background-color: #f9f9f9;
}

.custom-file-drop-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #666;
}

.custom-file-preview-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.custom-file-preview-pdf {
  width: 100%;
  height: 200px;
}

.custom-file-input {
  display: none;
}

.clear-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #999;
}

.custom-select {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  appearance: none;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.upload-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.upload-button:hover {
  background-color: #45a049;
}
