.custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 100vh;
}

.custom-download-list-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.custom-text-center {
  text-align: center;
}

.custom-download-list {
  list-style: none;
  padding: 10px;
  margin: 0;
  /* margin-bottom: 20px; */
  max-width: 400px;
  background-color: #e2e2f2;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-download-list-item {
  display: flex;
  align-items: center;
  padding: 18px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.no-tasks-para {
  text-align: center;
  font-size: 50px;
  color: #888;
  margin-top: 20px;
}
.custom-download-list-src {
  flex-grow: 1;
  margin-left: 10px;
}

.custom-download-list-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.custom-download-list-ext {
  margin-right: 5px;
}

.custom-download-list-size {
  text-align: right;
}

.custom-download-list-btn {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.custom-btn {
  color: white;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: background-color 300ms linear;
  background: blue;
  display: inline-block;
}

.custom-btn-primary:hover {
  background: darkblue;
}

@media (max-width: 768px) {
  .custom-download-list-wrap {
    flex-wrap: wrap;
  }

  .custom-download-list-item {
    width: 100%;
    margin-bottom: 10px;
  }

  .custom-download-list-src {
    margin-left: 0;
    text-align: center;
  }

  .custom-download-list-btn {
    margin-left: 0;
    justify-content: center;
  }
}
