.internship-container {
    max-width: 900px;
    margin: 0 auto;
  }
  .intern-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .apply_box {
    max-width: 600px;
    padding: 20px;
    background-color: white;
    margin: 0 auto;
    /* margin-top: 50px; */
    box-shadow: 4px 3px 5px rgba(1, 1, 1, 0.1);
    border-radius: 10px;
  }
  
  .title_small {
    font-size: 20px;
  }
  
  .form_container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  .form_control {
    display: flex;
    flex-direction: column;
  }
  
  .internship-label {
    font-size: 15px;
    margin-bottom: 5px;
  }
  
  .internship-input{
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
  }
  
  /* input:focus {
    outline-color: red;
  } */
  
  .button_container {
    display: flex;
  
    justify-content: flex-end;
    margin-top: 20px;
  }
  .apply-button {
    background-color:#7355F7;
    border: transparent solid 2px;
    padding: 5px 10px;
    color: white;
    border-radius: 8px;
    transition: 0.3s ease-in;
  }
  .apply-button:hover {
    background-color: #ccc;
    border: 2px solid #7355F7;
    color: black;
    transition: 0.3s ease-out;
    cursor: pointer;
  }
  .textarea_control {
    grid-column: 1 / span 2;
  }
  .textarea_control textarea {
    width: 100%;
  }
  
  @media (max-width: 460px) {
    .textarea_control {
      grid-column: 1 / span 1;
    }
  } ;