.popImg {
    height: 45px;
    border-radius: 50%;
    cursor: pointer!important;
    margin: 0.2rem 0 0 0.7rem;
}
.popUserImg {
    height: 60px;
    border-radius: 50%;
    margin-bottom: 8px;
}
.userName, 
.userEmail{
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 700;
}
.userEmail {
    margin: 0.1rem 0 0.5rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: rgba(0,0,0,.7);
}