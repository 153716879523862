/* Services component custom styles */
.services {
  padding: 4rem 0 2rem;
  width: 100%;
  background-color: #f4f7fa;
  font-family: "Poppins", sans-serif;
}

.services .miniTitle {
  font-size: 2.5rem;
  color: #444;
  margin-bottom: 1rem;
}

.services .sectionTitle {
  font-size: 1.5rem;
  color: #777;
}

.services .row {
  margin: 0 -15px;
}

.services .mb-4 {
  margin-bottom: 2rem;
}

.services .training-item {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
  padding: 1.5rem;
}

.services .training-item:hover {
  box-shadow: 0 12px 20px -5px rgba(61, 73, 96, 0.25);
  transform: translateY(-5px);
}

.services .service-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  /* background-color: #e2e8f0; */
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.services img {
  max-height: 100%;
  max-width: 100%;
}

.services h3 {
  font-size: 1.6rem;
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
  color: #444;
}

.services .service-info {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.7;
}

.services .apply-btn {
  background: #7355f7;
  color: #fff;
  font-size: 1rem;
  border: none;
  padding: 0.7rem 1.5rem;
  border-radius: 10px;
  margin-top: 1.5rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.services .apply-btn:hover {
  background: #4b24f5;
}

.services .no-results {
  font-size: 2rem;
  font-weight: bold;
  color: #777;
  margin-top: 4rem;
  text-align: center;
}
