.headerTitle{
    margin-bottom: 10px;
    font-size: 60px;
    font-weight: 800;
    font-family: sans-serif!important;
}
.headerContent {
    font-size: 18px;
    line-height: 1.5;
    color: #5e5e5e;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: 0.5rem 0 1rem
}

.miniTitle {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #777777;
    text-transform: uppercase;
}
.headerHighlight {
    color: #7355F7;
}
@media (max-width: 981px) {
   
    .headerTitle{
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: 700;
        font-family: sans-serif!important;
    }
}