.banner-img {
  background: url("../../Assets/images/cool-background.png");
  height: 710px;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-clip-path: polygon(100% 0, 100% 90%, 0 95%, 0 0);
  clip-path: polygon(100% 0, 100% 90%, 0 95%, 0 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-title {
  font-size: 45px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-shadow: -1px 0px 0px black, 1px 0px 0px black, 0px -1px 0px black,
    0px 1px 0px black;
}
.banner-title > h3 {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
}
.banner-span {
  color: #f3e308;
  font-weight: 700;
  text-shadow: -1px 0px 0px black, 1px 0px 0px black, 0px -1px 0px black,
    0px 1px 0px black;
}
.banner-button {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.banner-button a {
  flex-direction: column;
  text-decoration: none;
  border: 2px solid #f7cc53;
  border-radius: 20px;
  padding: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}
.banner-button a:hover {
  background: #f7cc53;
  transition: 1.2s ease;
  color: black;
  border: 2px solid #fff;
}
.small-tagline {
  font-size: 20px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.small-tagline p {
  color: #ffffff;
  top: 50%;
  opacity: 0.9;
  margin-bottom: 30px;
}
/* .social-media {
  display: flex;
  justify-content: center;
  gap: 29px;
  max-width: 100%;
  overflow-x: auto;
}

.social-media > img {
  width: 140px;
  height: auto;
  object-fit: contain;
} */
.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 100%;
  overflow-x: auto;
  /* Limit the width on mobile screens to prevent overflow */
  /* You can adjust the breakpoint (e.g., 600px) based on your design needs */
  @media (max-width: 600px) {
    max-width: 100%;
    overflow-x: hidden;
    /* Change the flex direction to stack the images vertically on smaller screens */
    flex-direction: column;
    align-items: center;
  }
}

.social-media > img {
  width: 140px;
  height: auto;
  object-fit: contain;
  /* Make images responsive to fit well on smaller screens */
  /* You can adjust the image size and other properties for different screen sizes */
  @media (max-width: 600px) {
    width: 100%; /* Images will take up the full width of the container on smaller screens */
    max-width: 140px; /* Limit the max-width to maintain image aspect ratio */
  }
}


@media (max-width: 640px) {
  .banner-title {
    font-size: 25px;
  }
}
