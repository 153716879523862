.payment-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .qr-code-container h5 {
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  