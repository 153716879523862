.latest-transactions {
    width: 90%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .latest-transactions + .latest-transactions {
    margin-top: 2.5em;
  }
  
  .latest-transactions h1 {
    font-weight: 700;
    line-height: 1.125;
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
  }
  
  .latest-transactions h2 {
    margin-top: .25em;
    color: #999;
    font-size: clamp(1.125rem, 2.5vw, 1.25rem);
    & + * {
      margin-top: 1.5em;
    }
  }
  
  .latest-transactions summary {
    background-color: #fff;
    position: relative;
    cursor: pointer;
    padding: 1em .5em;
    list-style: none; /* Remove arrow */
    &::-webkit-details-marker {
      display: none; /* Remove arrow */
    }
    &:hover {
      background-color: #f2f5f9;
    }
  }
  
  .latest-transactions summary div {
    display: flex;
    align-items: center;
  }
  
  .latest-transactions summary h3 {
    display: flex;
    flex-direction: column;
  }
  
  .latest-transactions summary small {
    color: #999;
    font-size: .875em;
  }
  
  .latest-transactions summary strong {
    font-weight: 700;
  }
  
  .latest-transactions summary span:first-child {
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    background-color: #f3e1e1;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 1.25em;
  }
  
  .latest-transactions summary span:last-child {
    font-weight: 700;
    margin-left: auto;
  }
  
  .latest-transactions summary:focus {
    outline: none;
  }
  
  .latest-transactions summary .plus {
    color: #289672;
  }
  
  .latest-transactions details {
    border-bottom: 1px solid #b5bfd9;
  }
  
  .latest-transactions details[open] {
    box-shadow: -3px 0 0 #b5bfd9;
  }
  
  .latest-transactions details:first-of-type {
    border-top: 1px solid #b5bfd9;
  }
  
  .latest-transactions details > div {
    padding: 2em 2em 0;
    font-size: .875em;
  }
  
  .latest-transactions dl {
    display: flex;
    flex-wrap: wrap;
  }
  
  .latest-transactions dl dt {
    font-weight: 700;
  }
  
  .latest-transactions dl div {
    margin-right: 4em;
    margin-bottom: 2em;
  }
  