.video-gallery {
  padding: 20px;
}

.video-item {
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.video-item:hover {
  transform: translateY(-5px);
}
/* .domain-tag {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
} */

.video-link {
  display: none;
}

.video-item:hover .video-link {
  display: block;
}

.thumbnail-overlay {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.video-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.video-item:hover .video-thumbnail {
  transform: scale(1.05);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.play-button::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 28px solid white;
}

.video-info {
  padding: 20px;
  background-color: #f1f1f1;
}

.video-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.video-description {
  font-size: 14px;
  color: #888;
  margin-bottom: 0;
}

.video-player-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio for responsive video */
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  } */
