.profile {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    background: #fff;
    text-align: center;
    margin: 2.5rem auto;
    border-radius: 0.3rem;
    max-width: 500px;
}

.profile h2 {
    font-size: 35px;
    font-weight: 500;
    padding: 0.5rem 0;
    margin-bottom: 0;
    background-color: #EFF2F7;
}

.profile img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 1rem 0;
}

.profile h3 {
    font-size: 1.5rem;
    margin: 0.6rem 0;
}

.profile h5 {
    font-size: 1.1rem;
    color: rgba(0,0,0,.7);
}

.profileInfo {
    padding: 1.3rem;
}

.mainBtn {
    background: #7355F7;
    padding: 13px 36px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
}
.mainBtn:hover {
    background-color: #4C25F5;
    color: black;
}