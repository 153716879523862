.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .not-found-image {
    height: 80vh;
    padding: 2rem 0 0 0;
  }
  
  @media (max-width: 768px) {
    .not-found-image {
      height: 25vh;
    }
  }
  